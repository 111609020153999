@import "../../../styles.scss";

.my-input {
  border: 1px solid transparent;
  border-color: var(--surface-1);
  background-color: var(--surface-2);
  min-height: 38px;
  border-radius: 4px;
  display: inline-flex;
  box-shadow: inset 0 0 0px 0.125em #111522;
  max-width: 100%;
  width: 75%;
  font-size: 1.25rem;
  height: 2.5em;
  border-radius: 0.375em;
  color: $grey-light;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
    no-repeat 75% 75%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  filter: invert(1);
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: darkgrey;
  opacity: 1; /* Firefox */
}
