@use "sass:color";
// @import "./fonts/alegreya/index.scss";
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 6, 2019 */
@font-face {
  font-family: "Calamity";
  src: url(./fonts/calamity-regular-webfont.woff2) format("woff2"),
    url(./fonts/calamity-regular-webfont.woff) format("woff"),
    url(./fonts/calamity-regular-webfont.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calamity";
  src: url(./fonts/calamity-bold-webfont.woff2) format("woff2"),
    url(./fonts/calamity-bold-webfont.woff) format("woff"),
    url(./fonts/calamity-bold-webfont.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Inter;
  src: url(./fonts/Inter-Regular.ttf) format("truetype");
}
$family-sans-serif: "Calamity", sans-serif;
body {
  margin: 0;
  font-family: $family-sans-serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// Set your brand colors
// $yellow: #dbc599;
$surface-1: hsl(224, 33%, 20%);
$surface-2: hsl(224, 25%, 27%);
$surface-3: hsl(224, 33%, 42%);
$surface-1-opaque: hsla(224, 33%, 20%, 90%);
$yellow: hsl(40, 48%, 73%);
$blue: $surface-1;

// $blue: #222b45;
$dark-blue: hsl(224, 33%, 5%);
$dark-blue-opaque: hsla(224, 33%, 5%, 90%);

// $dark-blue: #080a10;
// Update Bulma's global variables
$grey-dark: #dde1e3;
$grey-light: #e0e1e2;
$grey-darker: #797b7d;
$content-heading-color: #dde1e3;
$primary: $yellow;
$link: $yellow;
$white-ter: $blue;
$title-color: $grey-dark;
$text-strong: $grey-darker;
// Update some of Bulma's component variables
$body-background-color: $blue;
$navbar-background-color: $dark-blue;
$box-background-color: $blue;

$table-background-color: var(--surface-1);
$table-color: $content-heading-color;
$table-cell-heading-color: $content-heading-color;
$table-head-cell-color: $content-heading-color;
$tabs-toggle-link-border-color: var(--surface-2);
// $table-head-background-color: var(--dark-blue);
.fa-secondary {
  opacity: 0.4;
}
:root {
  --surface-1: #{$surface-1};
  --surface-2: #{$surface-2};
  --surface-1-opaque: #{$surface-1-opaque};
  // --surface-2: #354369;
  --surface-3: #{$surface-3};
  --yellow: #{$yellow};
  --dark-blue: #{$dark-blue};
}
.text-shadow {
  text-shadow: 0px 1px 6px rgba($color: #000000, $alpha: 0.7);
  padding-left: 0.2rem; // shadow
  padding-right: 0.2rem; // shadow

}
tbody {
  font-family: Inter, "Calamity", sans-serif;
}
thead th {
  font-weight: 100;
}
.boat-name {
  color: #e9eaec !important;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: $family-sans-serif;
  overflow: hidden;
  white-space: nowrap;
}
.body-text {
  font-family: Inter, "Calamity", sans-serif;
  font-display: optional;
}
.m-right-10 {
  margin-right: 10px;
}
.skill-description {
  font-family: "Concourse 3", $family-sans-serif;
  font-size: 1.25rem;
}
.type-icon {
  width: 38px;
  height: auto;
  object-fit: contain;
  // object-position: left;
}
.bg-blue {
  background: $blue;
}
.bg-blue-opaq {
  backdrop-filter: saturate(180%) blur(14px) !important;
  background: $surface-1-opaque !important;
}
.bg-dark-blue-opaq {
  backdrop-filter: saturate(180%) blur(14px) !important;
  background: $dark-blue-opaque !important;
}
.title.text-yellow,
.subtitle.text-yellow,
.text-yellow {
  color: $yellow;
}
.info-box {
  // display: flex;
  border-radius: 8px;
  height: 100%;
}
.Dropdown-menu .is-selected {
  border: 1px solid $yellow;
}
.my-dib {
  transform-origin: center;
  // display: inline;
}
.top-div {
  display: grid;
  position: relative;
  gap: 1rem;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: 1fr;
  grid-template-areas: ". .";
}
.modal-background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba($color: #000000, $alpha: 0.7);
}
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/components/level.sass";
@import "../node_modules/bulma/sass/components/menu.sass";
@import "../node_modules/bulma/sass/components/tabs.sass";

@import "../node_modules/bulma/sass/elements/content.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/sass/grid/columns.sass";
@import "../node_modules/bulma/sass/helpers/color.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/box.sass";
@import "../node_modules/bulma/sass/elements/button.sass"; // @import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/elements/table.sass"; // @import "../node_modules/bulma/sass/form/_all.sass";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "../node_modules/bulma/sass/helpers/visibility.sass"; // @import "../node_modules/bulma/sass/components/tabs.sass";
